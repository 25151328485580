<template>
	<nav class="site-nav-wrapper" role="navigation" >
		<div class="nav-button-wrapper">
			<div class="nav-button" v-on:click="showMobileMenu = !showMobileMenu" v-bind:class="{ 'nav-button--open': showMobileMenu }">
				<span></span>
			</div>
		</div>

		<!-- <div class="">
			<a v-for="(item, key) in navItems" class="site-nav__item" :class="navActive(item)" :href="item.link" :key="key" :title="item.title" @click.stop.prevent="navLink(item)">
				{{item.label}}
			</a>
		</div> -->
		<div class="nav">
			<div class="nav-overlay" v-bind:class="{ 'nav-overlay--visible': showMobileMenu }" v-on:click="showMobileMenu = !showMobileMenu"></div>
			<div class="nav-wrapper" v-bind:class="{ 'nav-wrapper--visible': showMobileMenu }">
				<div class="nav-logo">
					 <a href="/" v-on:click.native="showMobileMenu = !showMobileMenu"> Mike Parsons Ink</a>
				</div>
				<!-- <nav class="nav-items">
					<div class="nav-item" v-on:click="showMobileMenu = !showMobileMenu">
						<a href="/#home" v-smooth-scroll>Home</a>
					</div>
					<div class="nav-item" v-on:click="show = !show;">
						<a href="#">Our Artists</a>
					</div>
					<div class="nav-item" v-on:click="showMobileMenu = !showMobileMenu">
						<a href="/#shop" v-smooth-scroll>The Shop</a>
					</div>
					<div class="nav-item" v-on:click="showMobileMenu = !showMobileMenu">
						<a href="#contact" v-smooth-scroll>Contact + Appointments</a>
					</div>
				</nav> -->
				<nav class="nav-items">
					<div class="nav-item" v-on:click="showMobileMenu = !showMobileMenu">
						<a href="/#home" v-smooth-scroll>Home</a>
					</div>
					<div class="nav-item">
						<a href="../mike-parsons">Mike Parsons</a>
					</div>
					<div class="nav-item">
						<a href="../derek-raulerson">Derek Raulerson</a>
					</div>
					<div class="nav-item">
						<a href="../kurtis-todd">Kurtis Todd</a>
					</div>
					<div class="nav-item">
						<a href="../ryan-coniglario">Ryan Coniglario</a>
					</div>
					<div class="nav-item" v-on:click="showMobileMenu = !showMobileMenu">
						<a href="#contact" v-smooth-scroll>Locations</a>
					</div>
				</nav>
				<div class="nav-meta">
					<!-- <div class="nav-address">
						<meta itemprop="name" content="Mike Parsons Ink Tattoos">
						<a href="#" class="nav-contact-item nav-contact--email">Locations</a><br>
						<a href="#" class="nav-contact-item">Riverview, FL</a><br>
						<a href="#" class="nav-contact-item">Plant City, FL</a>
						<br>
					</div> -->
					
					<div class="nav-contact">
						<span class="nav-address-item">Appointments & Info:</span><br>
						Riverview: &nbsp; <a href="tel:813-374-2146" class="nav-contact-item nav-contact--phone">813-374-2146</a> <br>
						Plant City: &nbsp;  <a href="tel:813-704-5016" class="nav-contact-item nav-contact--phone">813-704-5016</a><br><br>
						<a href="mailto:mikeparsonsink@gmail.com" class="nav-contact-item nav-contact--email">mikeparsonsink@gmail.com</a><br /><br />
						
						<a href="http://www.facebook.com/pages/Mike-Parsons-Ink/102848876424242" class="nav-contact-item nav-contact--social">facebook</a>
						<a href="https://www.instagram.com/explore/locations/5672795/mike-parsons-ink/?hl=en" class="nav-contact-item nav-contact--social">instagram</a>
						<a href="https://x.com/MikeParsonsInk" class="nav-contact-item nav-contact--social">twitter</a>
					</div>
				</div>
			</div>
		</div>
		<transition class="transition" name="fade" mode="out-in">
			<div class="artist-container" v-if="show">
				<artists></artists>
			</div>
		</transition>
	</nav>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'
import Artists from '@/components/Artists'

// Export
export default {
	name: 'siteNav',
	components: {
		Artists
	},
	data: () => ({
		routes: [],
		showMobileMenu: false,
		show: false
	}),
	methods: {
		navLink(item) {
			if (!item.link) return
			if (item.link.indexOf('http') === 0) {
				window.location.href = item.link
			} else {
				this.$router.push({ path: item.link })
			}
		},
		navActive(item) {
			if (this.$route.path.indexOf(item.link) > 0) return 'site-nav__item--active'
			if (this.$route.path === item.link) return 'site-nav__item--active'
			return item.class
		},
	},
	computed: {
		...mapGetters([
			'navInternal',
		]),
		navItems() {
			const items = {}
			for (const key in this.navInternal) {
				if (Object.prototype.hasOwnProperty.call(this.navInternal, key)) {
					const item = this.navInternal[key]
					if (item.inHeader) items[key] = item
				}
			}
			return items
		},
	},
}
</script>

<style lang="scss" scoped>
.site-nav {
}

@mixin navbar()
{
	background-color: color_('white');
	border-radius: 0px;
	display: block;
	height: 2px;
	position: absolute;
	transition: transform $transition-cubic 350ms, background-color $transition-cubic 250ms;
	transform-origin: 50% 50%;
	width: 35px;
	
}

.nav-button {
	height: 100%;
	width: 100%;
	background: rgba(0,0,0,0.0);
	opacity: 1;
	transition: all $transition-cubic 300ms;

	&:hover {
		opacity: .5;
	}

	&-wrapper {
		//border: 3px solid color_('grey');
		border-radius: 50%;
		cursor: pointer;
		height: 60px;
		position: fixed;
		right: 16px;
   		top: 10px;
		z-index: 31;
		width: 60px;
		transform: translateZ(10px);

		opacity: 0;
		animation-fill-mode: forwards;
		animation-delay: 0ms;
		animation-duration: 1000ms;
		animation-name: fadeIn;
		animation-timing-function: linear;

		@media screen and (max-width: map-get($breakpoints, 'sm')) {
			// right: 20px;
			// top: 20px;
		}
	}

	span {
		@include navbar();
		left: 30px;
    	top: 30px;
		transform: translateY(0%) translateX(-50%);

		&:before,
		&:after {
			@include navbar();
			content: "";
		}
		&:before {
			transform: translateY(-500%);
			
		}
		&:after {
			transform: translateY(500%);
			
		}
	}

	&--open {
		span {
			background-color: transparent;
			transform: translateY(-50%) translateX(-50%) rotate(180deg);

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}
	}
}

.nav {
	z-index: 30;
	position: relative;

	&-view {

	}

	&-overlay {
		opacity: 0;
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		transition: $transition-cubic 300ms 300ms;

    	&--visible {
			opacity: 0.5;
    		pointer-events: auto;
		}
	}

	&-wrapper {
		display: flex;
    	flex-direction: column;
		background-color: color_('grey','dark');
		height: 100%;
		right: -400px;
		opacity: 0;
		padding: $margin 0;
		position: fixed;
		top: 0;
		bottom: 0;
		width: 400px;
		z-index: 10;
		justify-content: space-between;
		transition: right $transition-cubic 400ms 250ms, opacity $transition-cubic 300ms 300ms;

		&--visible {
			opacity: 1;
			right: 0;
		}
	}

	&-logo {
		font-family: $font-secondary;
		letter-spacing: 6px;
		color: color_('white');
		opacity: 0;
		transition: opacity $transition-cubic 600ms;
		transition-delay: 500ms;
		margin: 0px 26px $margin;
		font-size: 22px;

		a {
			color: rgba(255,255,255, 1);
			transition: color $transition-cubic 300ms;
			text-decoration: none;

			&:hover {
				color: rgba(255,255,255, .5);
			}
		}

		.nav-wrapper--visible & {
			opacity: 1;
		}

		&:hover {
			cursor: pointer;
		}
	}

	&-items {
		flex: 360px 0 1;
		justify-content: space-around;
		align-items: flex-start;
		display: flex;
		flex-direction: column;
//		margin-bottom: 2em;
//		text-align: center;
		margin-bottom: 40px;
		//max-height: 360px;

		// @media screen and (max-width: $screen-md-min) and (orientation: landscape)  {
		// 	flex-direction: row;
		// 	justify-content: space-around;
		// 	width: 100%;
		// }
	}

	&-item {
		opacity: 0;
		transition: all $transition-cubic 300ms;
		width: 100%;
		position: relative;

		&:after {
			content: "";
			position: absolute;
			display: block;
			height: 1px;
			bottom: 0;
			left: 0;
			width: 0%;
			transition: all $transition-cubic 300ms;
			background-color: color_('secondary');
		}

		&:hover {
			color: color_('white');
			cursor: pointer;

			&:after {
				width: 100%;
			}
		}

		a {
			color: color_('grey','light');
			text-decoration: none;
			font-size: 22px;
			padding: 15px $margin;
			transition: opacity ease 500ms;
			opacity: 1;
			font-family: $font-secondary;
			line-height: 1.2em;
			text-transform: lowercase;
			letter-spacing: 4px;
			font-weight: 700;
			width: 100%;
			display: block;
			transition: $transition-cubic 300ms;
			border-bottom: 1px solid color_('grey','medium');

			&:hover {
				color: color_('white');
			}
		}

		&--active {
			a {
				color: color_('secondary');
			}
		}

		.nav-wrapper--visible & {
			opacity: 1;

			&:nth-child(1) {
				transition-delay: 500ms;
			}
			&:nth-child(2) {
				transition-delay: 600ms;
			}
			&:nth-child(3) {
				transition-delay: 700ms;
			}
			&:nth-child(4) {
				transition-delay: 800ms;
			}
			&:nth-child(5) {
				transition-delay: 1000ms;
			}
			&:nth-child(6) {
				transition-delay: 1200ms;
			}
		}
	}

	&-meta {
		font-family:  $font-primary;
		font-size: 16px;
//		text-align: center;
		padding: 0 $margin;
		opacity: 0;
		transition: $transition-cubic 300ms;
		transition-delay: 1000ms;

		.nav-wrapper--visible & {
			opacity: 1;
		}

	}

	&-address {
		//margin-bottom: 0.5em;
		text-transform: uppercase;

		&-item {
			color: color_('white');
			line-height: 1.5em;
			display: block;
		}

		&-hidden {
			display: none;
			visibility: hidden;
			width: 0;
		}
	}

	&-contact {
		text-transform: uppercase;
		line-height: 1.5em;

		&-item {
			//color: color_('grey','light');
			color: #fff;
			text-decoration: none;
			transition: color ease 350ms;
			position: relative;
			top: -2px;
			margin-right: 10px;

			&:hover {
				color: color_('secondary');
			}
		}

		&--email {
			transition: color ease 350ms;
			font-size: 18px;
		}

		&--phone {
			transition: color ease 350ms;
			font-size: 20px;
		}

		&-icon {

			display: inline-block;
			font-size: 0.8em;
			text-align: center;
			padding-top: 0.2em;
			vertical-align: middle;
			height: 1.6em;
			width: 1.6em;
		}
	}
}

.artist-container {
		position: fixed;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		z-index: 32;
		overflow: auto;
		transform: translateY(0);
		transition: transform 400ms $easing-material, opacity 200ms $easing-material;

		&.fade-enter-active {
			//transition: opacity .5s;
			top: 100%;
			transform: translateY(100%);
			
		}

		&.fade-leave-active {
			opacity: 0;
			transform: translateY(100%);
		}
	}
</style>
