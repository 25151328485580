<template>
	<div class="antihero" id="contact">
		<div class="antihero__wrapper">
			<div class="antihero__span antihero__span--left">
				<h4 class="antihero__header">Mike Parsons Ink
<br> Riverview, FL</h4>
					<p>
					<span class="antihero__icon-block">
						<i class="icon">phone</i>
						<a href="tel:813-374-2146">813-374-2146</a>
					</span>
					<br>
					<span class="antihero__icon-block">
						<i class="icon">mail</i>
						<a href="mailto:mikeparsonsink@gmail.com">mikeparsonsink@gmail.com</a>
					</span>
				</p>
				<div class="antihero__content">
					<div class="grid__inner">
						<div class="grid__cell grid__cell--span-12 grid__cell--span-9-tablet">
							<p>
							<small><strong>ADDRESS:</strong></small><br>
							<a href="https://goo.gl/maps/AaVXf21dUcw" target="_blank" rel="noopener noreferrer">13346 Lincoln Rd<br /> Riverview, Fl 33578</a><br>
							
							</p>
						
						
							<p>
								<small><strong>SHOP HOURS:</strong></small><br>
								Tuesday-Saturday<br>
								Noon-8pm<br>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<static-map placeId="ChIJR5BAF9nWwogRsMd4Xhn_bKs" :styles="mapStyles" :size="{width:800, height:800}"></static-map>
		<!-- <div id="gmap"></div> -->
	</div>
</template>

<script>
// Components
import LearnForm from '@/components/LearnForm'
import StaticMap from '@/components/StaticMap'
import GoogleMapsLoader from 'google-maps'

// Export
export default {
	name: 'antiHero',
	components: {
		LearnForm,
		StaticMap
	},
	data: () => ({
		show: false,
		mapStyles: [
			{
				"featureType": "all",
				"elementType": "all",
				"stylers": [
					{
						"hue": "#ff0000"
					},
					{
						"saturation": -100
					},
					{
						"lightness": -30
					}
				]
			},
			{
				"featureType": "all",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "all",
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"color": "#353535"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#656565"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": "#505050"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"color": "#808080"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#454545"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "labels",
				"stylers": [
					{
						"hue": "#000000"
					},
					{
						"saturation": 100
					},
					{
						"lightness": -40
					},
					{
						"invert_lightness": true
					},
					{
						"gamma": 1.5
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"saturation": "44"
					},
					{
						"lightness": "-28"
					},
					{
						"hue": "#ff9900"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"saturation": "-6"
					},
					{
						"color": "#c27c7c"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"saturation": "0"
					},
					{
						"lightness": "8"
					},
					{
						"color": "#ae5252"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"lightness": "5"
					},
					{
						"color": "#3c3c3c"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"color": "#ff9900"
					},
					{
						"weight": "3.55"
					}
				]
			},
			{
				"featureType": "transit.station",
				"elementType": "labels.icon",
				"stylers": [
					{
						"saturation": "-83"
					},
					{
						"weight": "1.84"
					},
					{
						"visibility": "on"
					},
					{
						"gamma": "0.00"
					},
					{
						"color": "#ff9900"
					},
					{
						"lightness": "-6"
					}
				]
			}
		],
	}),
	mounted() {
		GoogleMapsLoader.KEY = 'AIzaSyBEb1ho00f1NJzsHjAG1JhuJBfg98bcvTQ'
		GoogleMapsLoader.LIBRARIES = ['places']
		// GoogleMapsLoader.load(google => {
		// 	this.initMap(google)
		// })
	},
	methods: {
		initMap(google) {
			// For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
			var mapOptions = {
				// How zoomed in you want the map to start at (always required)
				zoom: 9,

				// The latitude and longitude to center the map (always required)
				center: new google.maps.LatLng(27.7064654,-82.2448654),

				// How you would like to style the map.
				// This is where you would paste any style found on Snazzy Maps.
				styles: this.mapStyles,
			};

			// Get the HTML DOM element that will contain your map
			// We are using a div with id="map" seen below in the <body>
			var mapElement = document.getElementById('gmap');

			// Create the Google Map using our element and options defined above
			var map = new google.maps.Map(mapElement, mapOptions);

			var infowindow = new google.maps.InfoWindow();
			var service = new google.maps.places.PlacesService(map);
			var image = require('@/assets/images/map_icon.png');

			service.getDetails({
				placeId: 'ChIJR5BAF9nWwogRsMd4Xhn_bKs'
			}, function(place, status) {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
				var marker = new google.maps.Marker({
					map: map,
					position: place.geometry.location,
					icon: image
				});
				google.maps.event.addListener(marker, 'click', function() {
					infowindow.setContent('<div style="font-size: 20px;font-family: Bebas Neue, serif;font-weight: normal;line-height: 1.5;"><h4 style="margin-bottom:0;">' + place.name + '</h4>' +
					place.formatted_address + '<br>' + place.formatted_phone_number +
					'<br><strong><a href="' + place.url + '" style="color:#5189CF" target="_blank">GET DIRECTIONS &raquo;</a></strong><br /><br /></div>');
					infowindow.open(map, this);
				});
				infowindow.setContent('<div style="font-size: 20px;font-family: Bebas Neue, serif;font-weight: normal;line-height: 1.5;"><h4 style="margin-bottom:0;">' + place.name + '</h4>' +
					place.formatted_address + '<br>' + place.formatted_phone_number +
					'<br><strong><a href="' + place.url + '" style="color:#5189CF" target="_blank">GET DIRECTIONS &raquo;</a></strong><br /><br /></div>');
				infowindow.open(map,marker);
				}
			});
		},
	}
}
</script>

<style lang="scss" scoped>
.antihero {
	overflow: hidden;
	position: relative;
	// @include gutter('padding-bottom', 2);
	@include gutter('padding-top', 3);

	.grid {
		padding: 10px;
	}

	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}

	.fade-enter, .fade-leave-to {
		opacity: 0;
	}

	&__wrapper {
		color: color_('white');
		margin: 0 auto;
		max-width: 1280px;
		position: relative;
		z-index: 2;

		a {
			color: color_('white');
			transition: color 300ms $easing-material;
			text-decoration: none;

			&:hover {
				color: color_('secondary');
			}
		}

		p {
			line-height: 1.5em;
			font-size: 26px;
			font-weight: 600;
			color: color_('white');
			//@include gutter('margin-bottom', 1.5);

			@media screen and (max-width: map-get($breakpoints, 'lg')) {
				font-size: 20px;
			}
		}
	}

	&__content {
		@include gutter('margin-bottom', 3);	
	}

	&__span {
		flex: 1;
		max-width: 50%;
		@include gutter('padding-right');

		@media screen and (max-width: map-get($breakpoints, 'lg')) {
			max-width: 100%;
			@include gutter('padding-left');
			text-align: center;
        }

		&--left {
			@include gutter('padding-left',.75);
		}
	}

	&__header {
		@include font-size('h2');
		margin-top: 0;
		@include gutter('margin-bottom', .5);
		color: color_('secondary');

		@media screen and (max-width: map-get($breakpoints, 'md')) {
			font-size: 2.8rem;
		}
	}

	&__icon-block {
		display: inline-block;
		//font-family: $font-secondary;
		//font-size: 20px;
		vertical-align: middle;
		@include gutter('margin-right');

		.icon {
			@include gutter('margin-right', 0.25);
			transform: translateY(15%);
			font-size: 16px;
		}
	}

	&::after {
		background: url(../assets/images/black_background.jpg) #1e1e1e scroll repeat;
		content: ' ';
		height: 100%;
		left: 0;
		//opacity: 0.5;
		position: absolute;
		top: 0;
		//transform: translate3d(-30%, 0, 0) skew(-20deg, 0);
		width: 50%;
		z-index: 1;

		@media screen and (max-width: map-get($breakpoints, 'lg')) {
			width: 100%;
        }
	}

}

#gmap,
.map__wrapper {
	position: absolute;
	top: 0;
	right: 0;
	left: 50%;
	bottom: 0;
	overflow: hidden;
	z-index: 3;

	@media screen and (max-width: map-get($breakpoints, 'lg')) {
		position: relative;
		height: 700px;
		margin-bottom: -200px;
		left: 0;
	}
}
</style>
