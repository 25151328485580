<template>
	<div class="partial partial--home home">
		<hero id="home"></hero>
		<content-band id="about">
			<h2 class="content-band__title"><span>Award Winning Tattoos </span><br /> & Custom Art</h2>
			<p>Mike Parsons Ink is a world-class tattoo & custom art studio with locations in Riverview & Plant City, FL - founded by the acclaimed tattoo artist Mike Parsons. With over 25 years of experience in tattooing, illustration, & art direction, Mike has earned countless awards & widespread recognition for his groundbreaking work in the industry.</p>
			<p>Backed by a handpicked team of exceptionally skilled artists, Mike Parsons Ink is a place where creativity & craftsmanship come first. Every piece is approached as a custom work of art, blending bold ideas with expert technique to create tattoos that are as unique as the people wearing them.</p>
			<!-- <p class="text-center"><a href="#" class="btn">Read More</a></p> -->
		</content-band>
		<div class="home-artists-wrapper" id="artists">
			<artists></artists>
		</div>
		<content-band id="shop">
			<h2 class="content-band__title">The Shop</h2>
			<p>Mike Parsons Ink is a premier tattoo studio dedicated to clean, professional artistry in a safe & comfortable environment. We uphold the highest standards of hospital-grade sterilization, ensuring a hygienic experience you can trust. With locations in Riverview & Plant City, FL - just 20 minutes from Tampa, FL - our modern, new-school studio blends contemporary techniques, diverse styles, & cutting-edge safety practices to deliver exceptional tattoos.</p>
			<div slot="img-bottom" class="content-band__image" :style="shopImage"></div>
		</content-band>
		<button-gallery v-on:click.native="show = !show" v-bind:class="{ 'button-gallery--open': show }"></button-gallery>
		<transition class="transition" name="fade" mode="out-in">
			<div class="artist-container" v-if="show">
				<artists></artists>
			</div>
		</transition>
	</div>
</template>

<script>
// Components
import Hero from '@/components/Hero'
import ContentBand from '@/components/ContentBand'
import Artists from '@/components/Artists'
import ButtonGallery from '@/components/ButtonGallery'


// Export
export default {
	name: 'home',
	components: {
		Hero,
		ContentBand,
		Artists,
		ButtonGallery,
	},
	data: () => ({
		shopImage: {
			backgroundImage: 'url(' + require('@/assets/images/home__shop.jpg') + ')'
		},
		show: false
	}),
	metaInfo() {
		return {
			title: 'Mike Parsons Ink',
		}
	},
}
</script>

<style lang="scss" scoped>
.artist-container {
		position: fixed;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		z-index: 32;
		overflow: auto;
		transform: translateY(0);
		transition: transform 400ms $easing-material, opacity 200ms $easing-material;

		&.fade-enter-active {
			//transition: opacity .5s;
			top: 100%;
			transform: translateY(100%);
			
		}

		&.fade-leave-active {
			opacity: 0;
			transform: translateY(100%);
		}
	}
</style>
