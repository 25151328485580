<template>
	<footer class="footer" role="contentinfo" id="contact">
		<content-band id="about">
			<h2 class="content-band__title"><span>Shop Locations</span></h2>
			<p>Walk-ins are welcome but appointments are strongly recommended as most of our artists are by appointment only. Please contact the shops for artist availability</p>
			<p>Locations in Riverview and Plant City, FL just 20 minutes from Tampa, FL.</p>
			<!-- <p class="text-center"><a href="#" class="btn">Read More</a></p> -->
		</content-band>
		<location-riverview></location-riverview>
		<location-plantcity></location-plantcity>
		<div class="footer__credits">
			Copyright &copy; Mike Parsons Ink. All Rights Reserved.<br>
			Website by <a href="https://www.justinfinleydesign.com/" target="blank">JFD</a>
		</div>
	</footer>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'

// Components
import AntiHero from '@/components/AntiHero'
import ContentBand from '@/components/ContentBand'
import LocationRiverview from '@/components/LocationRiverview'
import LocationPlantcity from './LocationPlantcity.vue'

// Export
export default {
	name: 'siteFooter',
	components: {
		AntiHero,
		ContentBand,
		LocationRiverview,
		LocationPlantcity
	},
	methods: {
		navLink(item) {

		},
	},
	computed: {
		...mapGetters([
			'navInternal',
			'navExternal',
		]),
		navItems() {
			const items = {}
			for (const key in this.navInternal) {
				if (Object.prototype.hasOwnProperty.call(this.navInternal, key)) {
					const item = this.navInternal[key]
					if (item.inFooter) items[key] = item
				}
			}
			return items
		},
		year() {
			return (new Date()).getFullYear()
		},
	},
}
</script>

<style lang="scss" scoped>
.footer {
	//border-bottom: 20px solid color_('grey','dark');
	//@include gutter('padding-bottom');
	//@include gutter('padding-top');

	&__wrapper {
		color: color_('text');
		font-family: $font-secondary;
		font-size: 18px;
		line-height: 1.33;
		max-width: 1280px;
		@include gutter('padding-top', 2);
	}

	&-nav {
		display: flex;
		flex-direction: column;

		&__header {
			color: color_('text');
			font-family: $font-primary;
			font-size: 27px;
			font-weight: bold;
			margin-top: 0;
			@include gutter('margin-bottom');
		}

		&__item {
			color: color_('text', 'light');
			font-weight: normal;
			text-decoration: none;
			transition: color 250ms $easing-material;
			@include gutter('margin-bottom', 0.6);

			&:hover {
				color: color_('primary');
			}
		}
	}

	&-meta,
	&-info {
		font-size: 17px;
		line-height: 1.5;
		text-align: right;

		a {
			color: color_('text', 'light');
			text-decoration: none;
			&:hover {
				color: color_('primary');
			}
		}
	}

	&-info {
		text-align: left;
	}

	&-meta {
		&__social {
			@include gutter('margin-left', 0.5);
		}
	}

	&__credits {
		background: color_('grey','dark');
		@include gutter('padding');
		text-align: center;
		color: color_('grey');

		@media screen and (max-width: map-get($breakpoints, 'md')) {
			font-size: 16px;
		}
		
		a {
			color: color_('secondary');
			text-decoration: none;
		}
	}
}
</style>
