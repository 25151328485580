<template>
    <div class="map__wrapper">
        <!-- <div class="map" id="map">...map loading...</div> -->
        <div class="map__infowindow" :style="{maxWidth: `${size.width}px`}">
            <h4 style="margin: 0 0 12px;">Mike Parsons Ink - Riverview</h4>
            13346 Lincoln Rd <br> Riverview, FL 33578, USA<br>
            (813) 374-2146<br>
            <a href="https://maps.google.com/?cid=12352528362394666928" target="_blank" rel="noopener noreferrer">GET DIRECTIONS »</a>
        </div>
        <div class="map__image" :style="{backgroundImage:`url(${staticUrl})`}"></div>
        <!-- <img :src="staticUrl" alt="Map" class="map__image"> -->
    </div>
</template>

<script>

const styleConverter = json => {

    const _items = []
    const separator = '|'
    const isColor = value => /^#[0-9a-f]{6}$/i.test(value.toString())
    const toColor = value => '0x' + value.slice(1)

    _items.length = 0

    for (let i = 0; i < json.length; i++) {
        const item = json[i]
        const hasFeature = item.hasOwnProperty('featureType')
        const hasElement = item.hasOwnProperty('elementType')
        const stylers = item.stylers
        let target = ''
        let style = ''

        if (!hasFeature && !hasElement) {
            target = 'feature:all'
        } else {
            if (hasFeature) {
                target = `feature:${item.featureType}`
            }
            if (hasElement) {
                target = target ? target + separator : ''
                target += `element:${item.elementType}`
            }
        }

        for (let s = 0; s < stylers.length; s++) {
            const styleItem = stylers[s]
            const key = Object.keys(styleItem)[0]

            style = style ? style + separator : ''
            style += `${key}:`+ (isColor(styleItem[key]) ? toColor(styleItem[key]) : styleItem[key])
        }

        _items.push(target + separator + style)
    }

    return `&style=${_items.join('&style=')}`
}

export default {
    name: 'staticMap',
    props: {
        center: {
            type: Object,
            default: () => ({
                lat: 27.7903257,
                lng: -82.3426771,
            }),
        },
        zoom: {
            type: Number,
            default: 9,
        },
        scale: {
            type: Number,
            default: 2,
        },
        mapIcon: {
            type: String,
            default: require('@/assets/images/map_icon.png'),
        },
        placeId: String,
        styles: {
            type: Array,
            default: () => ([]),
        },
        apiKey: {
            type: String,
            default: 'AIzaSyBEb1ho00f1NJzsHjAG1JhuJBfg98bcvTQ',
        },
        features: {
            type: Array,
            default: () => (['places']),
        },
        size: {
            type: Object,
            default: () => ({
                width: 500,
                height: 500,
            }),
        },
    },
    data: () => ({
        liveMap: false,
    }),
    mounted() {

    },
    computed: {
        staticUrl() {
            const style = styleConverter(this.styles)
            return `https://maps.googleapis.com/maps/api/staticmap?center=${this.center.lat},${this.center.lng}&zoom=${this.zoom}&size=${this.size.width}x${this.size.height}&scale=${this.scale}&key=${this.apiKey}${style}&markers=color:blue%7Csize:mid%7C27.7903257,-82.3426771`
        },
    }
}
</script>

<style scoped lang="scss">
.map {
    &__wrapper {
        position: relative;
        width: 100%;
        max-width: 50%;
        @media screen and (max-width: map-get($breakpoints, 'lg')) {
            max-width: 100%;
        }
    }

    &__image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 2;
    }

    &__infowindow {
        background-color: white;
        border: 1px solid #cecece;
        border-radius: 3px;
        color: color_('grey','xdark');
        padding: 1rem;
        position: absolute;
        bottom: calc(50% - 50px);
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-family: Bebas Neue, serif;
        font-weight: normal;
        line-height: 1.5;
        width: 350px;
        z-index: 10;

        @media screen and (max-width: map-get($breakpoints, 'lg')) {
            bottom: calc(50% - 36px);
        }

        h4 {
            margin-bottom: 0;
            font-family: Cormorant, serif;
        }

        a {
            color: color_('secondary');
            // text-decoration: none;
        }

        $arrowSize: 16px;

        &:after {
            content: '';
            display: block;
            height: 0;
            width: 0;
            border-left: $arrowSize solid transparent;
            border-right: $arrowSize solid transparent;
            border-top: $arrowSize solid #fff;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
</style>
